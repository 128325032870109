* {
  box-sizing: border-box;
}
html, body { 
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
} */

td {
  font-size: 14px;
}

tr {
  font-size: 14px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.lu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.lu {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.chat-container {
  display: grid;
  height: 100vh;
  grid-template-areas: 
    "chat side-panel"
    "input side-panel";
  grid-template-rows: auto 58px;
  grid-template-columns: auto 450px;
  padding: 0px 24px 24px 24px;
  overflow: hidden;
}

.chat-history-content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.chat-history-title{
  font-size: 1.1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-weight: bold;
  /* padding-bottom: .5em; */
  line-height: 2em;
}
.history-container-opener{
  display: none !important;
}
@media (max-width: 1200px) {
  .chat-container {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 58px;
    grid-template-columns: auto;
    padding: 0px 24px 24px 24px;
  }

  .history-container {
    position: absolute;
    overflow-y: auto;
    right: 0;
    width: 400px;
    max-width: 100%;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    background: var(--colorNeutralBackground3);
    transition: transform .5s ease-in-out;
    box-shadow: var(--shadow4);
  }
  .history-container > *{
    flex-shrink: 0;
  }

  .history-container.open {
    transform: translateX(0);
  }
  .history-container-opener{
    display: block !important;
  }

}